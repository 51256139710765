/* meisin orion main.js */
export default function default_meisin() {
    const IS_HOME = document.getElementById('HomePage') ? true : false;
    const IS_LOADED = null === sessionStorage.getItem("isloaded") || "done" != sessionStorage.getItem("isloaded") ? false : true;
    // TODO debug print;
    IS_HOME ? console.log("Is Home Page.") : console.log("Is not Home Page.");
    IS_LOADED ? console.log("loaded") : console.log("not loaded");
    let requestAnimationFrame =
        window.requestAnimationFrame || window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

    function addEvent(elm, type, callback, option) {
        elm.addEventListener(type, callback, option);
    }

    function addClass(elm, className) {
        toggleClass(elm, className, 'add');
    }

    function delClass(elm, className) {
        toggleClass(elm, className, 'del');
    }

    function checkAndAddClass(elm, className) {
        if (!elm.classList.contains(className)) elm.classList.add(className);
    }

    function deleteAClassName(elm, className) {
        elm.classList.remove(className);
    }

    function toggleClass(elm, className, mode) {
        if (!Array.isArray(className) && (typeof className !== 'string')) return;
        var t = Array.isArray(className) ? className : className.split(' ');
        t.forEach((s) => typeof s === 'string' ? (mode === 'add' ? checkAndAddClass(elm, s) : (mode === 'del' ? deleteAClassName(elm, s) : void 0)) : void 0);
    }

    function excClass(elm, before, after) {
        delClass(elm, before);
        addClass(elm, after);
    }

    function getHeight(query) {
        return document.querySelector(query).clientHeight;
    }

    /* HOME画面固有 */
    IS_HOME ? function () {
        /* スライド画像の枚数 */
        const JSLIDE_COUNT = 4;
        const JSLIDE_INTERVAL = 5000;
        const OPENING_DURATION = 3600;
        const REMOVAL_DELAY = 1000;
        const CORPCOPY_DELAY = 1000;
        let slides = [];
        let counter = 0;
        let jsCorpPassion = document.getElementsByClassName("js-corp-passion").item(0);
        var stocks = document.getElementsByClassName("scroll-stock-image-container");
        var nav = document.getElementsByClassName('fx-nav');
        /* var hv = document.getElementById('hv'); */
        var opening = document.getElementById('Opening');
        var logoSvg = document.getElementsByClassName('meisin-logo-svg').item(0);

        for (let i = 1; i <= JSLIDE_COUNT; i++) {
            slides.push(document.querySelector(`.hv-slide.__no${i.toString().padStart(2, '0')}`))
        }
        // TODO debug log
        slides.forEach(s => console.log(s));

        // スクロール時
        function scrollFunc() {
            /* let top = document.documentElement.scrollTop;
            let hvh = hv.clientHeight;
      
            if (top > hvh) {
              !isFixed ? Array.from(nav).forEach(elm => addClass(elm, 'fixed')) : void 0;
              isFixed = true;
            } else {
              isFixed ? Array.from(nav).forEach(elm => delClass(elm, 'fixed')) : void 0;
              isFixed = false;
            } */

            // スクロールストックスクロール時処理
            /* Array.from(stocks).forEach((element) => {
              let height = ((h * counter) - top) < 0 ? 0 : ((h * counter) - top);
              height === 0 ? delClass(element, "fixed") : addClass(element, "fixed");
              element.style.height = height + 'px';
              counter++;
            }); */
        }

        // リサイズ時処理
        function resizeFunc() {
            var h = document.documentElement.clientHeight;
            var w = document.documentElement.clientWidth;
            // var homeContainerHeight = getHeight(".home-content-wrapper") - getHeight(".home-content-wrapper .home-title-wrapper");
            // document.documentElement.style.setProperty('--home-container-height', homeContainerHeight + 'px');

            document.getElementById('hv').style.width = w + "px";
            // スクロールストックリサイズ時
            Array.from(stocks).forEach((element) => {
                element.style.width = w + 'px';
                element.firstElementChild.style.paddingBottom = h + 'px';
            });
        }

        function sliderStart() {
            delClass(slides[0], 'na wait');
            setInterval(jslideFunc, JSLIDE_INTERVAL);
        }

        /** スライダー処理
         *  カウンターの数字によって表示する画像要素のクラスを追加変更する
         */
        function jslideFunc() {
            counter = (counter > (JSLIDE_COUNT - 1)) ? 0 : counter;
            var current = (counter - 1) < 0 ? JSLIDE_COUNT - 1 : counter - 1;
            var nextnum = counter;

            addClass(slides[counter], 'pre-act'); // pre-act でスライド最前面に表示
            setTimeout(() => {
                // 指定秒後にpre-act から is-act に書き換え
                excClass(slides[nextnum], 'pre-act', 'is-act');
                // 直前の画像要素から、is-act を削除
                delClass(slides[current], 'is-act');
            }, 500);

            counter++;
        }

        function corpCopyActivate() {
            addClass(jsCorpPassion, 'is-act');
        }

        /* 企業コピーのアニメーション */
        function jsPassionSpliter() {
            let pary = jsCorpPassion.innerHTML.replace(/\n/g, '').replace(/\s/g, '').split(/<br.*\/*>/gi);
            let buf = '', nl = '<br>', cn = 1;
            Array.from(pary).forEach((str) => {
                Array.from(str).forEach((c) => {
                    buf += `<span class="c${cn}" style="transition-delay: ${100 * cn++}ms">${c}</span>`;
                });
                buf += nl;
            });
            jsCorpPassion.innerHTML = buf.replace(/<br>$/gi, '');
        }

        function openingFunc() {
            // オープニングアニメーション表示済みの場合はLOGO-SVGアニメを開始、未表示の場合はオープニングアニメ開始
            if (IS_LOADED) {
                // オープニングアニメーション表示済みの場合はLOGO-SVGアニメを開始
                addClass(logoSvg, 'is-act');
                openingCommon();
            } else {
                // 未表示の場合はオープニングアニメ開始
                addClass(opening, 'is-act');
                setTimeout(() => { openingCommon(); }, OPENING_DURATION);
                // アニメーション表示済みフラグをセット（コメントアウトで常に表示）
                sessionStorage.setItem('isloaded', 'done');
            }
        }

        function openingCommon() {
            // SVG LOGO のアニメーションを開始
            addClass(logoSvg, 'is-act');
            // オープニングアニメーション要素を削除
            setTimeout(() => opening.remove(), REMOVAL_DELAY);
        }

        document.readyState === 'loading' ? addEvent(document, 'DOMContentLoaded', openingFunc) : openingFunc();

        jslideFunc(); // 
        jsPassionSpliter();

        if (IS_LOADED) {
            addClass(slides[0], 'na');
            setTimeout(() => {
                sliderStart();
                setTimeout(() => corpCopyActivate(), CORPCOPY_DELAY);
            }, 0);
        } else {
            addClass(slides[0], 'wait');
            setTimeout(() => {
                sliderStart();
                setTimeout(() => corpCopyActivate(), CORPCOPY_DELAY);
            }, OPENING_DURATION);
        }
        addEvent(window, 'resize', resizeFunc);
        addEvent(window, 'scroll', scrollFunc);
        resizeFunc();
        scrollFunc();
    }() : void 0;

    /* ヘッダーロゴ */
    /* !function() {
      function resizeFunc() {
        
      }
      addEvent(window, 'resize', resizeFunc);
      resizeFunc();
    }(); */

    // TODO 消す
    var statusSpan = document.getElementById("DebugStatus");

    /* 共通 */
    !function () {
        // Observable
        var obs = document.getElementsByClassName('obs');
        // ヘッダーロゴ
        var logoElements = document.getElementsByClassName('header-logo');
        // ハンバーガーボタン
        var isActive = false;
        var pageHeader = document.getElementById('PageHeader');
        var spnav = document.getElementsByClassName('sp-nav');
        var navLinks = document.getElementsByClassName('navlink');
        var hamburgerMap = {
            'navBarLine1': 'line-upper',
            'navBarLine2': 'line-middle',
            'navBarLine3': 'line-bottom'
        };
        // Rellax.js
        var rellax;
        const Rellax = require('rellax');

        function rellaxFunc() {
            var w = window.innerWidth;
            if (w > 991) {// PC 環境での処理
                if (!rellax) {
                    rellax = document.querySelectorAll('.rellax').length > 0 ? new Rellax('.rellax', { center: true }) : undefined;
                } else {
                    rellax.refresh();
                }
            } else {
                if (rellax) {
                    if (Object.keys(rellax).length) rellax.destroy();
                }
            }
        }

        rellaxFunc();
        addEvent(window, 'resize', rellaxFunc);

        function activate() {
            // ハンバーガーボタン
            Object.keys(hamburgerMap).forEach((key) => addClass(document.getElementById(key), hamburgerMap[key]));
            Array.from(spnav).forEach((e) => addClass(e, 'is-act')), isActive = true;
        }

        function deactivate() {
            // ハンバーガーボタン
            Object.keys(hamburgerMap).forEach((key) => delClass(document.getElementById(key), hamburgerMap[key]));
            Array.from(spnav).forEach((e) => delClass(e, 'is-act')), isActive = false;
        }

        function hamburgerFunc() {
            // ハンバーガーボタン
            (window.innerWidth >= 992) ? deactivate() : isActive ? deactivate() : activate();
        }

        function resizeFunc() {
            // ヘッダーロゴ
            var w = window.innerWidth;

            if (w < 768 || w >= 992) {
                Array.from(logoElements).forEach(element => element.style.left = null);
            } else {

                Array.from(logoElements).forEach(element => element.style.left = null);
                /* let iw = document.querySelector("header h1 img").width, pos = (w - iw) / 2;
                Array.from(logoElements).forEach(element => element.style.left = pos + 'px'); */
            }

            if (w > 991) {// PC 環境での処理
                // ハンバーガーボタン 隠す
                deactivate();
                // rellax.js
                delClass(pageHeader, 'sp-nav__active');

            } else {
                // SP
                addClass(pageHeader, 'sp-nav__active');
                // rellax.js
                if (rellax) {
                    if (Object.keys(rellax).length) rellax.destroy();
                }
            }
        }

        var isFixed = false;
        var nav = document.getElementsByClassName('fx-nav');
        var hv = document.getElementById('hv');

        function scrollFunc() {
            let top = document.documentElement.scrollTop;
            let hvh = hv.clientHeight;

            if (top > hvh) {
                !isFixed ? Array.from(nav).forEach(elm => addClass(elm, 'fixed')) : void 0;
                isFixed = true;
            } else {
                isFixed ? Array.from(nav).forEach(elm => delClass(elm, 'fixed')) : void 0;
                isFixed = false;
            }
        }

        // mega-menu visible or hidden
        let megaMenuItems = document.querySelectorAll('header.fixed-top .nav-list .menu-item-has-children');
        Array.from(megaMenuItems).forEach(elm => {
            addEvent(elm, 'mouseenter', mouseEnterFunc);
            addEvent(elm, 'mouseleave', mouseLeaveFunc);
        });

        function mouseEnterFunc() {
            var w = window.innerWidth;
            if (w > 991) {// PC 環境での処理
                Array.from(nav).forEach(elm => addClass(elm, 'mega-menu-visible'));
            } else {
                // SP
                Array.from(nav).forEach(elm => delClass(elm, 'mega-menu-visible'));
            }
        }

        function mouseLeaveFunc() {
            var w = window.innerWidth;
            Array.from(nav).forEach(elm => delClass(elm, 'mega-menu-visible'));
        }

        addEvent(window, 'resize', resizeFunc);
        //addEvent(window, refreshRellax());
        resizeFunc();
        addEvent(window, 'scroll', scrollFunc);
        scrollFunc();
        addEvent(document.getElementById('navBarButton'), 'click', hamburgerFunc);
        addEvent(document.getElementById('menuCloseButton'), 'click', hamburgerFunc);
        Array.from(navLinks).forEach(link => addEvent(link, 'click', hamburgerFunc));
    }();

    /* Parallax */
    var parallaxInit = function () {
        let ptargets = document.getElementsByClassName('jparallax');
        let viewHeight = document.documentElement.clientHeight; // ビューポート高さ
        Array.from(ptargets).forEach((target) => {
            let scale = Number(target.getAttribute('data-parallax-scale') || 0.4);
            let seed = scale * 100;
            let targetHeight = target.clientHeight;
            let voffset = targetHeight * 0.5;
            let offsetHeight = viewHeight + targetHeight;
            let offsetPosition = (target.getBoundingClientRect().top + window.scrollY) + voffset;

            function doParallax() {
                requestAnimationFrame((timestamp) => {
                    var w = window.innerWidth;
                    let tagName = target.tagName;
                    if (w > 767) {// PC 環境での処理
                        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                        let scrollBottom = scrollTop + viewHeight;
                        let offsetTop = scrollTop - voffset;
                        let offsetBottom = scrollBottom + voffset;
                        if ((offsetBottom > offsetPosition) && (offsetPosition > offsetTop)) {
                            let ratio = 100 - (((offsetHeight - (offsetPosition - offsetTop)) / offsetHeight) * 100);
                            let moval = (50 - ratio) * (seed / 50);
                            let movalValue = '50% calc(50% + ' + moval + 'px)';
                            if (tagName.toUpperCase() == 'IMG') {
                                target.style.objectPosition = movalValue;
                            } else {
                                target.style.backgroundPosition = movalValue;
                            }
                        }
                    } else {
                        // SP
                        if (tagName.toUpperCase() == 'IMG') {
                            target.style.objectPosition = '50% 50%';
                        } else {
                            target.style.backgroundPosition = '50% 50%';
                        }
                    }
                });
            }

            window.addEventListener('scroll', (e) => {
                doParallax();
            });
            doParallax();
        });
    }

    window.addEventListener('load', parallaxInit);
    window.addEventListener('resize', parallaxInit);

    if (!!window.IntersectionObserver) {
        /* interSectionObserver */
        let tElm;

        let options = {
            root: null,
            rootMargin: "0px",
            threshold: buildThresholdList(20)
        };
        let observer = new IntersectionObserver(intersectionHandler, options);

        window.addEventListener('load', (e) => {
            tElm = document.getElementsByClassName('obs');
            createObserver();
        }, false);

        function createObserver() {
            for (let i = 0; i < tElm.length; i++) {
                observer.observe(tElm[i]);
            }
        }

        function buildThresholdList(numSteps) {
            let thresholds = [];
            for (let i = 1.0; i <= numSteps; i++) {
                let ratio = i / numSteps;
                thresholds.push(ratio);
            }
            thresholds.push(0);
            return thresholds;
        }

        function intersectionHandler(entries, observer) {
            entries.forEach((entry) => {
                //entry.intersectionRatio > 0.30 ? addClass(entry.target, 'is-act') : delClass(entry.target, 'is-act');
                if (entry.intersectionRatio > 0.0) {
                    addClass(entry.target, 'is-act');
                    if (!entry.target.classList.contains('obs-infinite')) {
                        observer.unobserve(entry.target);
                    }
                } else {
                    if (entry.target.classList.contains('obs-infinite')) {
                        delClass(entry.target, 'is-act');
                    }
                }
            });
        }
    } else {
        // if intersectionObserver not supported
        console.log('intersectionObserver not supported.');
        let elments = document.getElementsByClassName('obs');
        function obsScrollFunc(e) {
            Array.from(elments).filter(target => !target.classList.contains('is-act')).forEach((target) => {
                let vy1 = document.documentElement.scrollTop;
                let vy2 = vy1 + document.documentElement.clientHeight;
                let ty1 = vy1 + target.getBoundingClientRect().top;
                let ty2 = ty1 + target.clientHeight;
                let clientHeight = target.clientHeight;
                let rate = 0.0;
                if (ty1 > vy2 || vy1 > ty2) {
                    return;
                } else if (ty2 <= vy2 && vy1 <= ty1) {
                    rate = 1.0;
                } else if (ty2 > vy2 && vy2 >= ty1) {
                    rate = (vy2 - ty1) === 0 ? 0.00 : (vy2 - ty1) / clientHeight;
                } else if (vy1 > ty1 && ty2 >= vy1) {
                    rate = (ty2 - vy1) === 0 ? 0.00 : (ty2 - vy1) / clientHeight;
                }
                if (rate > 0) addClass(target, 'is-act');
            });
        }
        addEvent(window, 'scroll', obsScrollFunc);
    }
};

