import {initBootstrap} from "./bootstrap";
import 'rellax';
import default_meisin from './default_meisin';
import '../scss/main.scss';
import '../images/logos/logo_header_fixed.png';
import '../images/logos/logo_header.png';
import '../images/main/default-img-wave.jpg';

initBootstrap({
    tooltip: true,
    popover: true,
    toasts: true,
});

default_meisin();
